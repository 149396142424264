angular.module("app")
    .directive("cTimelineElement", function($q, _calendar) {
        return {
            restrict: "A",
            link: function ($scope, $element, attr) {

                reposition();

                //todo remove duplicate listener
                $scope.$on("_calendar:changed", reposition);
                $scope.$on("_calendar:width-changed", reposition);

                $scope.$on('_c-field-panel:update', reposition);
                $scope.$on('_c-field-panel:init', reposition);


                function reposition() {
                    var timelineElement = _.getProperty($scope, attr.cTimelineElement);
                    var leftPromise = _calendar.getPositionForDatePromise(timelineElement.from).then(function (pos) {
                        return pos;
                    });

                    var rightPromise = _calendar.getPositionForDatePromise(timelineElement.to).then(function (pos) {
                        return pos;
                    });

                    var boundaries = _calendar.getDisplayedDateBoundariesPromise().then(function (calendarBoundaries) {
                        return calendarBoundaries;
                    });

                    $q.all({
                        left: leftPromise,
                        right: rightPromise,
                        boundaries: boundaries
                    }).then(function (result) {
                        var left = 0, width = 0;
                        var gap = 10;
                        if (result.boundaries) {
                            if (result.left == null && result.right == null) {
                                if (timelineElement.from.isBefore(result.boundaries.from) && timelineElement.to.isAfter(result.boundaries.to)) {
                                    left = -gap;
                                    width = 100 + 2 * gap;
                                }
                            } else if (result.left != null && result.right != null) {
                                left = result.left;
                                width = (result.right - result.left);
                            } else if (result.left == null && result.right != null) {
                                left = -gap;
                                width = result.right + gap;
                            } else if (result.left != null && result.right == null) {
                                left = result.left;
                                width = 100 + gap;
                            }
                        }

                        $element.css({
                            left: left + "%",
                            width: width + "%"
                        });
                    });
                }
            }
        }
    });